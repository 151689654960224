import { useMemo } from 'react';

interface SelectColumnFilterProps {
  column: any;
}

export default function SelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: SelectColumnFilterProps) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [preFilteredRows, id]);
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className='w-100'>
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option as string}>
          {option as string}
        </option>
      ))}
    </select>
  );
}
