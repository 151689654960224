import { useContext, useState, createContext, useEffect } from 'react';
import Axios from 'axios';
import protectedApi from './API/ProtectectedApi';
interface LoginCredentials {
  username: string;
  password: string;
}
interface ContextType {
  AuthState: boolean | undefined;
  userType: 'Admin' | 'DataEntry' | undefined;
  Login: (credentials: LoginCredentials) => Promise<void>;
  Logout: VoidFunction;
}
const AuthContext = createContext<ContextType>({
  AuthState: undefined,
  Login: async () => {},
  Logout: async () => {},
  userType: undefined,
});

const UserTypes = ['Admin', 'DataEntry'];

const AuthProvider: React.FC = ({ children }) => {
  const [authState, setAuthState] = useState<boolean>();
  const [userType, setUserType] = useState<'Admin' | 'DataEntry'>();
  useEffect(() => {
    async function initialLoad() {
      try {
        const initialToken = await protectedApi.RequestNewRefreshTokenGlobal();
        if (UserTypes.includes(initialToken.userType)) {
          setAuthState(true);
          setUserType(initialToken.userType);
        } else setAuthState(false);
      } catch (err) {
        setAuthState(false);
      }
    }
    initialLoad();
  }, []);
  async function Login(credentials: LoginCredentials) {
    const res = await protectedApi.Login(credentials);
    setAuthState(true);
  }

  async function Logout() {
    try {
      await protectedApi.logout();
      setAuthState(false);
    } catch {
      setAuthState(false);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        AuthState: authState,
        userType: userType,
        Login: Login,
        Logout: Logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
const useAuth = () => useContext(AuthContext);

export { AuthProvider as default, useAuth };
