import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../utils/authContext';
export default function Navigation() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { Logout } = useAuth();
  const { userType } = useAuth();
  return (
    <div className='sideNavMain'>
      <img
        className='sidNavLogo'
        src={`${process.env.PUBLIC_URL}/assets/img/ultra%20teb%20final%20white.a0f9608a.svg`}
      />
      <h6 style={{ color: 'white', font: 'ROBTO' }}>ADMIN</h6>
      <div className='subNavList'>
        <div
          className={`subNavListItem ${pathname === '/' ? 'active' : ''}`}
          onClick={() => {
            navigate('/');
          }}>
          <i className='fas fa-sitemap'></i>
        </div>
        <div
          className={`subNavListItem ${
            pathname === '/products' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/products');
          }}>
          <i className='fas fa-box'></i>
        </div>
        <div
          className={`subNavListItem ${
            pathname === '/vendors' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/vendors');
          }}>
          <i className='fas fa-warehouse'></i>
        </div>
        <div
          className={`subNavListItem ${pathname === '/buyers' ? 'active' : ''}`}
          onClick={() => {
            navigate('/buyers');
          }}>
          <i className='fas fa-hospital'></i>
        </div>
        <div
          className={`subNavListItem ${pathname === '/orders' ? 'active' : ''}`}
          onClick={() => {
            navigate('/orders');
          }}>
          <i className='fas fa-shopping-bag'></i>
        </div>
        <div
          className={`subNavListItem ${
            pathname === '/collections' ? 'active' : ''
          }`}
          onClick={() => {
            navigate('/collections');
          }}>
          <i className='fas fa-shopping-basket'></i>
        </div>
      </div>
      <i
        onClick={Logout}
        className='fas fa-sign-out-alt'
        style={{
          color: 'white',
          fontSize: '2.5rem',
          position: 'absolute',
          bottom: '1.2rem',
          left: '1rem',
          cursor: 'pointer',
        }}></i>
    </div>
  );
}
