import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import ApproveOrder from '../components/editComponenets/approveOrder';
import MarkPayment from '../components/editComponenets/markPayment';
import BooleanFilter from '../components/filters/booleanFilter';
import DateFilter from '../components/filters/dateFilter';
import NumberFilter from '../components/filters/numberFilter';
import SelectFilter from '../components/filters/selectFilter';
import MainTable from '../components/table';
import protectedApi from '../utils/API/ProtectectedApi';
import { useAuth } from '../utils/authContext';
import { BooleanDisplay, DateDisplay } from '../utils/display';
import { dateFilter } from '../utils/helperFunctions';
import { Order } from '../utils/responses/orders';

export default function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    async function getOrders() {
      const res = await protectedApi.getOrders();
      setOrders(res.data.data);
      setIsLoading(false);
    }
    getOrders();
  }, []);
  const [skipPageReset, setSkipPageReset] = useState(false);
  useEffect(() => {
    setSkipPageReset(false);
  }, [orders]);
  const { userType } = useAuth();
  const columns: any = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
      },
      {
        Header: 'Buyer Name',
        accessor: 'buyer.name',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectFilter,
        filter: 'equal',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: DateDisplay,
        Filter: DateFilter,
        filter: dateFilter,
      },
      {
        Header: 'is Paid',
        accessor: 'isPaid',
        Cell: BooleanDisplay,
        Filter: BooleanFilter,
        filter: 'equals',
      },
      {
        Header: 'Payment Method',
        accessor: 'paymentMethod',
        Filter: SelectFilter,
        filter: 'equal',
      },
      {
        Header: 'Sub-Total',
        accessor: 'subTotal',
        Filter: NumberFilter,
        filter: 'between',
        Footer: (info: any) => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum: any, row: any) => row.values.subTotal + sum,
                0
              ),
            [info.rows]
          );
          return <>Total = {total.toFixed(2)}</>;
        },
      },
      {
        Header: 'Taxes',
        accessor: 'taxes',
        Filter: NumberFilter,
        filter: 'between',
        Footer: (info: any) => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum: any, row: any) => row.values.taxes + sum,
                0
              ),
            [info.rows]
          );
          return <>Total = {total.toFixed(2)}</>;
        },
      },
      {
        Header: 'Total',
        accessor: 'totalPrice',
        Filter: NumberFilter,
        filter: 'between',
        Footer: (info: any) => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum: any, row: any) => row.values.totalPrice + sum,
                0
              ),
            [info.rows]
          );
          return <>Total = {total.toFixed(2)}</>;
        },
      },
      {
        Header: 'Delivered At',
        accessor: 'deliveredAt',
        Cell: DateDisplay,
      },
      {
        Header: 'Cancelled At',
        accessor: 'cancelledAt',
        Cell: DateDisplay,
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
      },
      {
        Header: 'Delivery Number',
        accessor: 'deliveryTicketNumber',
      },
      {
        Header: 'Cancelled By',
        accessor: 'cancelledBy',
      },

      {
        Header: 'Approve Order',
        Cell: ({ cell }: { cell: Cell<Order> }) => {
          return <ApproveOrder cell={cell} />;
        },
      },
      {
        Header: 'Pay',
        Cell: ({ cell }: { cell: Cell<Order> }) => {
          return <MarkPayment cell={cell} />;
        },
      },

      {
        Header: 'Full Order',
        Cell: ({ cell: { value } }: { cell: { value: any } }) => {
          return <Link to={`/orders/${value}`}>Order</Link>;
        },
        disableFilters: true,
        disableSortBy: true,
        accessor: '_id',
        id: 'orderId',
      },
    ],

    []
  );
  function updateData(rowIndex: number, columnId: string, value: any) {
    setSkipPageReset(true);
    setOrders((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }

  if (isLoading)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}></span>
        </div>
      </div>
    );
  if (orders.length === 0)
    return (
      <div className='row'>
        <div className='col'>
          <div className='center-notFound'>
            <h1>
              <i className='fas fa-glasses'></i>&nbsp;No Products Found
            </h1>
            <button
              className='btn btn-secondary w-50'
              onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </div>
      </div>
    );
  else
    return (
      <MainTable
        data={orders}
        columns={columns}
        title='Orders'
        hiddenColumnsProp={[
          'cancelledBy',
          'deliveryTicketNumber',
          'invoiceNumber',
          'cancelledAt',
          'deliveredAt',
          'orderId',
          'Approve Order',
          'Pay',
        ]}
        skipPageRef={skipPageReset}
      />
    );
}
