import { useFormContext } from 'react-hook-form';
import { ProductInput } from '../../../utils/productInputs';
import { ErrorMessage } from '@hookform/error-message';
export default function Numbers() {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductInput>();

  return (
    <>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Minimum Order Quantity
            <input
              className='form-control'
              type='number'
              {...register('MOQ', {
                valueAsNumber: true,
                required: 'MOQ is required',
                min: { value: 1, message: 'Minimum value is 1' },
              })}
            />
          </label>
          <ErrorMessage
            errors={errors}
            name='MOQ'
            render={({ message }) => <p className='errorMessage'>{message}</p>}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Sales Quantity
            <input
              className='form-control'
              type='number'
              {...register('baseQuantity', { valueAsNumber: true })}
            />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Items per Box
            <input
              className='form-control'
              type='number'
              {...register('itemsPerBox', {
                valueAsNumber: true,
                required: 'Items per box is required',
                min: { value: 1, message: 'Minimum value is 1' },
              })}
            />
            <ErrorMessage
              errors={errors}
              name='itemsPerBox'
              render={({ message }) => (
                <p className='errorMessage'>{message}</p>
              )}
            />
          </label>
        </div>
      </div>
    </>
  );
}
