import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { Row } from 'react-table';
import { Product } from './responses/products';
import dayjs from 'dayjs';
import isBetweenDayJs from 'dayjs/plugin/isBetween';
import { PurchaseOrders } from './responses/orders';

export function getDirtyFieldsData<Type>(
  dirtyFields: FieldNamesMarkedBoolean<Type>,
  isDirty: boolean,
  data: Type
) {
  let patchData: Partial<Type> = {};
  const keys = Object.keys(dirtyFields);
  if (!isDirty || keys.length === 0) return undefined;
  keys.forEach((el) => {
    // @ts-ignore
    patchData[el] = data[el];
  });
  return patchData;
}

dayjs.extend(isBetweenDayJs);
function isBetween(filterValue: any, row: Row<Product>) {
  const rowDate = dayjs(row.original.createdAt);
  if (filterValue[0] && filterValue[1])
    return rowDate.isBetween(filterValue[0], filterValue[1], 'day');
  if (filterValue[0] && !filterValue[1])
    return rowDate.isAfter(filterValue[0], 'day');
  if (!filterValue[0] && filterValue[1])
    return rowDate.isBefore(filterValue[1], 'day');

  return true;
}

export function dateFilter(
  rows: Row<Product>[],
  columnIds: string[],
  filterValue: any
) {
  return rows.filter((el) => isBetween(filterValue, el));
}

export function sellingPriceTotals(purchaseOrder: PurchaseOrders) {
  const subTotal = purchaseOrder.items.reduce(
    (sum, item) => (sum += item.sellingPrice),
    0
  );
  const taxes = subTotal * 0.14;
  const total = subTotal + taxes;
  return { subTotal, taxes: taxes.toFixed(2), total: total.toFixed(2) };
}
