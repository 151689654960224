import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import protectedApi from '../../utils/API/ProtectectedApi';
import { getDirtyFieldsData } from '../../utils/helperFunctions';
import { Product, ProductVariant } from '../../utils/responses/products';
import { PriceModifiers } from '../../utils/responses/priceModifiers';

export default function PriceModifierEdit(props: PriceModifiers) {
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, isDirty },
  } = useForm<PriceModifiers>({
    defaultValues: props,
  });
  const [isLoading, setIsLoading] = useState(false);

  async function submitModifier(data: PriceModifiers) {
    const patchData = getDirtyFieldsData<PriceModifiers>(
      dirtyFields,
      isDirty,
      data
    );
    if (!patchData?.modifier) return;
    try {
      setIsLoading(true);
      setError(undefined);
      await protectedApi.patchModifier(props._id, patchData.modifier);
      setIsLoading(false);
    } catch (err) {
      if (axios.isAxiosError(err))
        setError(err.response?.data.errors[0].message);
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <tr>
        <td>
          <input
            className='form-control'
            {...register('variantId', { required: 'Variant ID is required' })}
            type='text'
          />
        </td>
        <td>
          <input
            className='form-control'
            {...register('buyerId', { required: 'Buyer ID is required' })}
            type='text'
          />
        </td>
        <td>
          <input
            className='form-control'
            {...register('modifier', { valueAsNumber: true })}
            type='text'
          />
        </td>

        <td>
          <button
            className='btn btn-dark'
            type='button'
            onClick={handleSubmit(submitModifier)}
          >
            {isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>{' '}
                {'Loading...'}
              </>
            ) : (
              'Save'
            )}
          </button>
        </td>
      </tr>
      {error && (
        <tr>
          <td colSpan={7}>{error}</td>
        </tr>
      )}
    </React.Fragment>
  );
}
