import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { DeepPartial } from 'react-hook-form';
import { ProductEdit, ProductInput } from '../productInputs';
import { UserBuyer } from '../responses/buyer';
import {
  CollectionResponse,
  FullCollection,
  newCollection,
} from '../responses/collection';
import { Order, SingleOrder } from '../responses/orders';
import { PaginationResponse } from '../responses/paginationResponse';
import { ProductLog } from '../responses/productLogs';
import { ProductImages, Product, ProductVariant } from '../responses/products';
import { UserVendor } from '../responses/vendor';
import { PriceModifiers } from '../responses/priceModifiers';
export interface RefreshToken {
  username: string;
  userType: 'Admin';
  accessToken: string;
}
export interface Login {
  username: string;
  userType: string;
  email: string;
  accessToken: string;
}

interface AxiosErrInterceptor extends AxiosRequestConfig {
  _retry: boolean;
}
export interface ProductPostData extends Omit<ProductInput, 'imagesUpload'> {
  vendorId: string;
  imagesUpload?: FileList;
}

export type ProductPatchData = DeepPartial<ProductEdit>;
class ProtectedApi {
  private instance: AxiosInstance;
  private interceptorId: number;
  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      withCredentials: true,
    });
    this.interceptorId = this.AddRefreshInterceptor();
  }

  private AddRefreshInterceptor() {
    const interceptor = this.instance.interceptors.response.use(
      undefined,
      this.RefreshInterceptor
    );
    return interceptor;
  }
  private EjectInterceptor() {
    this.instance.interceptors.response.eject(this.interceptorId);
  }

  private setToken(token: string) {
    this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  public async RequestNewRefreshTokenGlobal() {
    const res = await axios.post<RefreshToken>(
      '/sessions/refresh_tokens',
      undefined,
      {
        baseURL: `${process.env.REACT_APP_API}`,
        withCredentials: true,
      }
    );
    return res.data;
  }
  private async RequestNewRefreshToken() {
    try {
      const token = await this.RequestNewRefreshTokenGlobal();
      this.setToken(token.accessToken);
      return token;
    } catch (err: any) {
      throw err;
    }
  }

  private RefreshInterceptor = async (err: AxiosError) => {
    const originalConfig: AxiosErrInterceptor = {
      ...err.config,
      _retry: false,
    };
    if (
      err.response?.status === 401 &&
      !originalConfig._retry &&
      originalConfig.headers
    ) {
      const newToken = await this.RequestNewRefreshToken();
      originalConfig._retry = true;
      originalConfig.headers.Authorization = `Bearer ${newToken.accessToken}`;
      return this.instance(originalConfig);
    }
    return Promise.reject(err);
  };

  public async Login(credentials: { username: string; password: string }) {
    this.EjectInterceptor();
    const res = await this.instance.post<Login>('/sessions/login', {
      ...credentials,
      userType: ['Admin', 'DataEntry'],
    });
    this.AddRefreshInterceptor();
    this.setToken(res.data.accessToken);
    return res;
  }

  public async getProducts(vendorId?: string) {
    const res = await this.instance.get<PaginationResponse<Product>>(
      'admin/products',
      {
        params: { limit: 10000, vendorId: vendorId },
      }
    );
    return res.data;
  }

  public async patchProduct(slug: string, data: ProductPatchData) {
    await this.instance.patch(`admin/products/${slug}`, data);
  }

  public async patchVariant(
    productId: string,
    variantId: string,
    data: Partial<ProductVariant>
  ) {
    await this.instance.patch(
      `admin/products/${productId}/variants/${variantId}`,
      data
    );
  }
  public async postVariant(productId: string, data: ProductVariant) {
    await this.instance.post(
      `admin/products/${productId}/variants/create_variant`,
      data
    );
  }

  public async getVendors() {
    const res = await this.instance.get<PaginationResponse<UserVendor>>(
      'admin/users/vendors',
      { params: { limit: 200 } }
    );
    return res;
  }

  public async getBuyers() {
    const res = await this.instance.get<PaginationResponse<UserBuyer>>(
      'admin/users/buyers',
      { params: { limit: 200 } }
    );
    return res;
  }

  public async getOrders() {
    const res = await this.instance.get<PaginationResponse<Order>>(
      'admin/orders',
      {
        params: { limit: 1000 },
      }
    );
    return res;
  }

  public async getPriceModifiers(productId: string) {
    const res = await this.instance.get<PriceModifiers[]>(
      `/admin/modifiers/${productId}`,
      {
        params: { productId },
      }
    );
    return res;
  }

  public async postModifier(data: PriceModifiers) {
    const res = await this.instance.post(
      `/admin/modifiers/${data.productId}`,
      data
    );
    return res;
  }

  public async patchModifier(_id: string, modifier: number) {
    const res = await this.instance.patch(`/admin/modifiers/${_id}`, {
      modifier,
    });
    return res;
  }

  public async getOneOrder(orderId: string) {
    const res = await this.instance.get<SingleOrder>(`admin/orders/${orderId}`);
    return res;
  }

  public async getOneProduct(slug: string) {
    const res = await this.instance.get<Product>(`admin/products/${slug}`);
    return res;
  }
  public async patchOneUser(
    username: string,
    data: Partial<UserBuyer | UserVendor>
  ) {
    const res = await this.instance.patch(`/admin/users/${username}`, data);
    return res;
  }
  public async approveOrder(id: string) {
    const res = await this.instance.post(`/admin/orders/${id}/approve`);
  }
  public async payOrder(id: string) {
    const res = await this.instance.post(`/admin/orders/${id}/pay_cash`);
  }

  public async getCollections() {
    const res = await this.instance.get<CollectionResponse>(
      '/admin/collections',
      { params: { limit: 50 } } // Hardcoded for simplicity to be fixed later by adding pagination in collections page
    );
    return res;
  }

  public async getSearchResults(searchType: string, q: string) {
    if (searchType === 'name')
      return await this.instance.get<Product[]>('/admin/products/search/name', {
        params: { q },
      });
    if (searchType === 'id')
      return await this.instance.get<Product[]>('/admin/products/search/_id', {
        params: { q },
      });
    return undefined;
  }

  public async requestNewCollection() {
    const res = await this.instance.get<newCollection>(
      `/admin/collections/new/`
    );
    return res;
  }

  public async getImageUrls(_id: string) {
    const res = await this.instance.get<newCollection>(
      `/admin/collections/new/${_id}`
    );
    return res;
  }

  public async postCollection(data: {
    _id?: string;
    coverImage?: string;
    products: string[];
    name: string;
    arabicName: string;
    description: string;
  }) {
    const res = await this.instance.post<{ _id: string; slug: string }>(
      '/admin/collections',
      data
    );
    return res;
  }
  public async getCollection(slug: string) {
    const res = await this.instance.get<FullCollection>(
      `/admin/collections/${slug}`
    );
    return res;
  }

  public async editCollection(
    slug: string,
    data: {
      coverImage?: string;
      products: string[];
      name: string;
      arabicName: string;
      description: string;
    }
  ) {
    await this.instance.patch(`/admin/collections/${slug}`, data);
  }

  public async getLogs() {
    return await this.instance.get<ProductLog[]>(`/admin/products/changelog`);
  }

  public async requestNewProduct(vendorId: string) {
    return await this.instance.get<ProductImages>(
      `/admin/products/new/${vendorId}`
    );
  }

  public async postOneProduct(data: ProductPostData) {
    return await this.instance.post('/admin/products', data);
  }

  public async getImageEditLinks(slug: string) {
    return await this.instance.get<ProductImages>(
      `/admin/products/${slug}/images/new`
    );
  }

  public async deleteCollection(slug: string) {
    return await this.instance.delete(`/admin/collections/${slug}`);
  }

  public async deleteVariant(productId: string, variantId: string) {
    await this.instance.delete(
      `admin/products/${productId}/variants/${variantId}`
    );
  }

  public async logout() {
    await this.instance.post('/sessions/logout');
  }
}

const protectedApi = new ProtectedApi();
export default protectedApi;
