import { Modal } from 'bootstrap';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import protectedApi from '../../utils/API/ProtectectedApi';
import { ProductVariant } from '../../utils/responses/products';

interface ModalProps {
  setVariants: (data: ProductVariant) => void;
  productId: string;
  modalRef: React.RefObject<HTMLDivElement>;
  modalState: Modal | undefined;
}

export default function ProductVariantModal({
  modalRef,
  productId,
  setVariants,
  modalState,
}: ModalProps) {
  const { register, handleSubmit } = useForm<ProductVariant>();
  const [error, setError] = useState(false);

  async function handleVariantSubmit(data: ProductVariant) {
    try {
      await protectedApi.postVariant(productId, data);
      setVariants(data);
      modalState?.hide();
    } catch {
      setError(true);
    }
  }

  return (
    <div className='modal' tabIndex={-1} ref={modalRef}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Add Variant</h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => {
                modalState?.hide();
              }}></button>
          </div>
          <div className='modal-body'>
            <div className='mb-3 form-check'>
              <label className='form-label'> Name</label>
              <input
                type='text'
                {...register('name')}
                className='form-control'
              />
            </div>
            <div className='mb-3 form-check'>
              <label className='form-label'> Arabic Name</label>
              <input
                type='text'
                {...register('arabicName')}
                dir='rtl'
                className='form-control'
              />
            </div>
            <div className='mb-3 form-check'>
              <label className='form-label'> Price</label>
              <input
                type='text'
                {...register('price', { valueAsNumber: true })}
                className='form-control'
              />
            </div>
            <div className='mb-3 form-check'>
              <label className='form-label'> Stock</label>
              <input
                type='text'
                {...register('stock', { valueAsNumber: true })}
                className='form-control'
              />
            </div>
            <div className='mb-3 form-check'>
              <input
                type='checkbox'
                {...register('isHidden')}
                className='form-check-input'
              />
              <label className='form-check-label'> Is Hidden</label>
            </div>
            {error && (
              <div className='mb-3'>
                <h3>
                  An Error occurred when adding variant please check data and
                  try again!
                </h3>
              </div>
            )}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                modalState?.hide();
              }}>
              Close
            </button>
            <button
              type='button'
              className='btn btn-dark'
              onClick={handleSubmit(handleVariantSubmit)}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
