import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProductInput } from '../../utils/productInputs';

export default function Images() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<ProductInput>();
  const [currentImages, setCurrentImages] = useState<
    { url: string; fileName: string }[]
  >([]);
  const [coverImage, images] = watch(['coverImage', 'images']);
  const [coverImagesDisplay, setCoverImageDisplay] = useState<string>();
  const { ref, ...rest } = register('imagesUpload', {
    onChange: (e) => getBlobsAndSetState(e),
    validate: {
      max: (value) => value.length + images.length < 5 || 'Max images is 4',
      min: (value) => value.length + images.length > 0 || 'Min is 1',
    },
  });

  function getBlobsAndSetState(e: any) {
    const files = [...e.target.files];
    const urls = files.map((el) => {
      return {
        url: URL.createObjectURL(el),
        fileName: el.name,
      };
    });
    setCurrentImages([...urls]);
  }

  useEffect(() => {
    setCoverImageDisplay(() => {
      if (currentImages) {
        for (let i = 0; i < currentImages?.length; i++) {
          if (currentImages[i].fileName === coverImage)
            return currentImages[i].url;
        }
      }
      for (let z = 0; z < images.length; z++) {
        if (images[z] === coverImage)
          return `https://${process.env.REACT_APP_CLOUDFRONT}/${images[z]}`;
      }
    });
  }, [coverImage]);

  return (
    <>
      <div className='col'>
        <label className='form-label w-100'>
          Product Images
          <br />
        </label>

        <div className='d-flex justify-content-center align-items-center border w-100 image-upload-box rounded text-center p-3'>
          <input
            {...rest}
            type='file'
            multiple
            hidden={true}
            name='imagesUpload'
            accept='image/*'
            ref={(e) => {
              ref(e);
              fileInputRef.current = e;
            }}
          />
          <button
            className='btn btn-secondary'
            type='button'
            onClick={() => {
              fileInputRef.current?.click();
            }}>
            Upload Images
            <i
              className='fas fa-file-upload p-2'
              style={{ fontSize: 'larger' }}></i>
          </button>
        </div>
        <div className='align-items-center border w-100 image-upload-box rounded'>
          <p className='uploadImgParagraph'>
            Select Product's Main Cover Image
          </p>
          <div className='image-preview p-2 text-center w-100'>
            {currentImages?.map((el, i) => (
              <React.Fragment key={el.url}>
                <input
                  type='radio'
                  id={el.url}
                  value={el.fileName}
                  {...register('coverImage', {
                    required: 'CoverImage is required',
                  })}
                  hidden={true}
                />
                <label htmlFor={el.url} className='imgSelContainer'>
                  <div className='image-preview-crop shadow'>
                    <img className='image-preview-img' src={el.url} />
                  </div>
                </label>
              </React.Fragment>
            ))}
            {images.map((el) => (
              <React.Fragment key={el}>
                <input
                  type='radio'
                  id={el}
                  value={el}
                  {...register('coverImage', {
                    required: 'CoverImage is required',
                  })}
                  hidden={true}
                />
                <label htmlFor={el} className='imgSelContainer'>
                  <div className='image-preview-crop shadow'>
                    <img
                      className='image-preview-img'
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/${el}`}
                    />
                  </div>
                </label>
              </React.Fragment>
            ))}
          </div>
          <div className='text-end'>
            <p
              className='me-2 mb-1 fw-light delLink'
              onClick={() => {
                setCurrentImages([]);
              }}>
              Clear
            </p>
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name={'imagesUpload'}
          render={({ message }) => <p className='errorMessage'>{message}</p>}
        />
      </div>
      <div className='col-2 border-dark border-end'>
        <label className='form-label w-100'>
          Selected Cover Image
          <br />
        </label>
        <div className='d-flex justify-content-center align-items-center border w-100 image-upload-box rounded text-center p-3'>
          <img className='selCoverImg' src={coverImagesDisplay} />
        </div>
        <ErrorMessage
          errors={errors}
          name={'coverImage'}
          render={({ message }) => <p className='errorMessage'>{message}</p>}
        />
      </div>
    </>
  );
}
