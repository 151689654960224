import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../utils/authContext';

export default function Login() {
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  }>({
    username: '',
    password: '',
  });
  const { Login } = useAuth();
  function handleInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  }
  const navigate = useNavigate();
  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await Login(credentials);
    navigate('/');
  }
  return (
    <div className='d-flex flex-column justify-content-center align-items-center LoginBG'>
      <div className='d-flex justify-content-center align-items-center'>
        <img
          className='LoginLogo'
          src='assets/img/ultra%20teb%20final%20white.a0f9608a.svg'
        />
        <div>
          <h1 className='m-0 LoginTitle'>
            ULTRA<strong>TEB</strong>
          </h1>
          <h1 className='m-0 LoginTitle'>
            <em>ADMIN&nbsp;</em>
            <i className='fas fa-user-lock'></i>
          </h1>
        </div>
      </div>
      <div className='LoginBox'>
        <h1 className='text-center mb-3'>
          <i className='fas fa-key'></i>&nbsp;Log In
        </h1>
        <form method='post'>
          <h2 className='visually-hidden'>Login Form</h2>
          <div></div>
          <div className='mb-3'>
            <input
              className='form-control'
              name='username'
              placeholder='Username'
              onChange={handleInput}
            />
          </div>
          <div className='mb-3'>
            <input
              className='form-control'
              type='password'
              name='password'
              placeholder='Password'
              onChange={handleInput}
            />
          </div>
          <div className='mb-3'>
            <button
              className='btn btn-dark d-block w-100'
              type='submit'
              onClick={handleSubmit}>
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
