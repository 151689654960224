import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router';
import { Cell, TableState } from 'react-table';
import BooleanEdit from '../components/editComponenets/booleanEdit';
import BooleanFilter from '../components/filters/booleanFilter';
import DateFilter from '../components/filters/dateFilter';
import NumberFilter from '../components/filters/numberFilter';
import SelectFilter from '../components/filters/selectFilter';
import ProductsTable from '../components/productsTable';
import openApi from '../utils/API/openApi';
import protectedApi from '../utils/API/ProtectectedApi';
import { useAuth } from '../utils/authContext';
import { BooleanDisplay, DateDisplay } from '../utils/display';
import { dateFilter } from '../utils/helperFunctions';
import { Product } from '../utils/responses/products';

export default function Products(props: any) {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const [skipPageReset, setSkipPageReset] = useState(false);
  useEffect(() => {
    setSkipPageReset(false);
  }, [products]);
  const { userType } = useAuth();
  const Columns: any = useMemo(
    () => [
      {
        Header: '',
        id: 'Expand',
        Cell: ({ row }: { row: any }) => {
          return (
            <span {...row.getToggleRowExpandedProps()}>
              {!row.isExpanded ? (
                <i className='far fa-plus-square'></i>
              ) : (
                <i className='far fa-minus-square'></i>
              )}
            </span>
          );
        },
      },
      {
        Header: 'Image',
        accessor: 'coverImage',
        Cell: ({ cell: { value } }: { cell: { value: any } }) => {
          return (
            <img
              width='50px'
              height='50px'
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/${value}`}></img>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      { Header: 'ID', accessor: '_id' },
      {
        Header: 'Name [EN]',
        accessor: 'name',
      },
      {
        Header: 'Name [AR]',
        accessor: 'arabicName',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: DateDisplay,
        Filter: DateFilter,
        filter: dateFilter,
      },
      {
        Header: 'Category',
        accessor: 'category.name',
        Filter: SelectFilter,
      },
      {
        Header: 'Item Type',
        accessor: 'itemType.name',
        Filter: SelectFilter,
      },
      {
        Header: 'Vendor',
        accessor: 'vendor.name',
        Filter: SelectFilter,
      },
      {
        Header: 'Min Order',
        accessor: 'MOQ',
        Filter: NumberFilter,
        filter: 'between',
      },
      {
        Header: 'Base Qty',
        accessor: 'baseQuantity',
        Filter: NumberFilter,
        filter: 'between',
      },
      {
        Header: 'Items/ Box',
        accessor: 'itemsPerBox',
        Filter: NumberFilter,
        filter: 'between',
      },
      {
        Header: 'inStock',
        accessor: (row: Product) => {
          return row.variants.some(
            (el) => el.stock > 0 || el.hasUnlimitedStock
          );
        },
        Cell: BooleanDisplay,
        Filter: BooleanFilter,
        filter: 'equals',
        id: 'stock',
      },
      {
        Header: 'Approved?',
        accessor: 'isApproved',
        Cell:
          userType === 'Admin'
            ? ({ cell }: { cell: Cell<Product> }) => {
                return (
                  <BooleanEdit
                    cell={cell}
                    field='isApproved'
                    updateData={updateData}
                  />
                );
              }
            : BooleanDisplay,
        Filter: BooleanFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: 'Hidden?',
        accessor: 'isHidden',
        Cell: BooleanDisplay,
        Filter: BooleanFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: 'Edit',
        accessor: 'slug',
        Cell: (data: any) => {
          return (
            <i
              className='fas fa-pen editIcon'
              onClick={() => {
                navigate(`/products/${data.cell.value}`, {
                  state: { ...data.state, pathname },
                });
              }}></i>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );

  function updateData(rowIndex: number, columnId: string, value: any) {
    setSkipPageReset(true);
    setProducts((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }

  useEffect(() => {
    async function getProducts() {
      const res = await protectedApi.getProducts(vendorId);
      setProducts(res.data);
      setIsLoading(false);
    }
    getProducts();
  }, []);
  if (isLoading)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}></span>
        </div>
      </div>
    );
  if (products.length === 0)
    return (
      <div className='row'>
        <div className='col'>
          <div className='center-notFound'>
            <h1>
              <i className='fas fa-glasses'></i>&nbsp;No Products Found
            </h1>
            <button
              className='btn btn-secondary w-50'
              onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </div>
      </div>
    );
  else
    return (
      <ProductsTable
        data={products}
        columns={Columns}
        skipPageRef={skipPageReset}
      />
    );
}
