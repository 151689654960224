import { useMemo } from 'react';
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { UserBuyer } from '../utils/responses/buyer';
import { Items, Order } from '../utils/responses/orders';
import { ProductLog } from '../utils/responses/productLogs';
import { UserVendor } from '../utils/responses/vendor';
import DefaultColumnFilter from './filters/defaultFilter';
import { PriceModifiers } from '../utils/responses/priceModifiers';

interface MainTableProps {
  data:
    | UserVendor[]
    | UserBuyer[]
    | Order[]
    | Items[]
    | ProductLog[]
    | PriceModifiers[];
  columns: Column<
    UserVendor | UserBuyer | Order | Items | ProductLog | PriceModifiers
  >[];
  title: string;
  hiddenColumnsProp?: string[];
  skipPageRef: boolean;
}

const MainTable: React.FC<MainTableProps> = ({
  columns,
  data,
  title,
  hiddenColumnsProp,
  skipPageRef,
}) => {
  console.log(data);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setAllFilters,
    setHiddenColumns,
    setSortBy,
    allColumns,
    headers,
    headerGroups,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    page,
    state: { pageIndex, pageSize, hiddenColumns },
  } = useTable<
    UserVendor | UserBuyer | Order | Items | ProductLog | PriceModifiers
  >(
    {
      data: data,
      columns: columns,
      defaultColumn: defaultColumn,
      initialState: {
        pageSize: 20,
        hiddenColumns: hiddenColumnsProp ? hiddenColumnsProp : [],
      },
      autoResetPage: !skipPageRef,
      autoResetExpanded: !skipPageRef,
      autoResetGroupBy: !skipPageRef,
      autoResetSelectedRows: !skipPageRef,
      autoResetSortBy: !skipPageRef,
      autoResetFilters: !skipPageRef,
      autoResetRowState: !skipPageRef,
    },

    useFilters,
    useSortBy,
    usePagination
  );
  return (
    <div className='mainContent'>
      <div className='tableContainer'>
        <div className='d-flex justify-content-between align-items-center tableTitle'>
          <h5 className='m-2 fw-bold'>
            <i className='fas fa-table'></i>&nbsp;{title}
          </h5>
          <div className='tableControls'>
            <h6 className='m-0'>Show Columns</h6>
            <div className='dropdown px-2'>
              <button
                className='btn btn-dark btn-sm dropdown-toggle'
                aria-expanded='false'
                data-bs-toggle='dropdown'
                type='button'
              >
                {allColumns.length - (hiddenColumns ? hiddenColumns.length : 0)}{' '}
                Columns&nbsp;
              </button>
              <div className='dropdown-menu columnsList'>
                <div className='row'>
                  {allColumns.map((column) => (
                    <div className='col-md-6' key={column.id}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={column.id}
                          {...column.getToggleHiddenProps()}
                        />
                        <label className='form-check-label' htmlFor={column.id}>
                          {column.Header?.toString()}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='dropdown-divider'></div>
                <a
                  className='dropdown-item'
                  onClick={() => setHiddenColumns([])}
                >
                  Show All
                </a>
              </div>
            </div>
            <h6 className='m-0'>Items per Page</h6>
            <select
              className='form-select-sm form-select mx-2 selCollection'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <button
              className='btn btn-dark btn-sm mx-2'
              type='button'
              onClick={() => {
                setAllFilters([]);
              }}
            >
              Clear Filters
            </button>
            <button
              className='btn btn-dark btn-sm mx-2'
              type='button'
              onClick={() => setSortBy([])}
            >
              Clear Sort
            </button>
          </div>
        </div>
        <div className='mainTable'>
          <div>
            <table
              className='table table-sm table-bordered'
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => {
                  return (
                    <tr>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <div {...column.getSortByToggleProps()}>
                            {column.render('Header')}{' '}
                            <div
                              className={`sortSelector ${
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? 'sortDn'
                                    : 'sortUp'
                                  : ''
                              }`}
                            >
                              <i className='fa fa-sort-down m-0 p-0 sortSelectorDn'></i>
                              <i className='fa fa-sort-up m-0 p-0 sortSelectorUp'></i>
                            </div>
                          </div>
                        </th>
                      ))}
                    </tr>
                  );
                })}

                {headerGroups.map((headerGroup) => {
                  return (
                    <tr>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </th>
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center contentFooter'>
          <div className='btn-group' role='group'>
            <button
              className='btn btn-dark btn-sm'
              onClick={() => gotoPage(0)}
              type='button'
              disabled={!canPreviousPage}
            >
              <i className='fas fa-angle-double-left'></i>
            </button>
            <button
              className='btn btn-dark btn-sm'
              type='button'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <i className='fas fa-chevron-left'></i>
            </button>
            <button className='btn btn-dark btn-sm' type='button'>
              {pageIndex + 1} Of {pageCount}
            </button>
            <button
              className='btn btn-dark btn-sm'
              type='button'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <i className='fas fa-chevron-right'></i>
            </button>
            <button
              className='btn btn-dark btn-sm'
              type='button'
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <i className='fas fa-angle-double-right'></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainTable;
