import { Modal } from 'bootstrap';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import protectedApi from '../../utils/API/ProtectectedApi';
import { ProductVariant } from '../../utils/responses/products';
import { PriceModifiers } from '../../utils/responses/priceModifiers';

interface ModalProps {
  setPriceModifiers: (data: PriceModifiers) => void;
  productId: string;
  modalRef: React.RefObject<HTMLDivElement>;
  modalState: Modal | undefined;
}

export default function PriceModifierModal({
  modalRef,
  productId,
  setPriceModifiers,
  modalState,
}: ModalProps) {
  const { register, handleSubmit } = useForm<PriceModifiers>();
  const [error, setError] = useState(false);

  async function handleSubmitModifier(data: PriceModifiers) {
    try {
      await protectedApi.postModifier({ ...data, productId });
      setPriceModifiers(data);
      modalState?.hide();
    } catch {
      setError(true);
    }
  }

  return (
    <div className='modal' tabIndex={-1} ref={modalRef}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Add Price Modifier</h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => {
                modalState?.hide();
              }}
            ></button>
          </div>
          <div className='modal-body'>
            <div className='mb-3 form-check'>
              <label className='form-label'> Variant ID</label>
              <input
                type='text'
                {...register('variantId')}
                className='form-control'
              />
            </div>
            <div className='mb-3 form-check'>
              <label className='form-label'> Buyer ID</label>
              <input
                type='text'
                {...register('buyerId')}
                className='form-control'
              />
            </div>
            <div className='mb-3 form-check'>
              <label className='form-label'> Modifier</label>
              <input
                type='text'
                {...register('modifier', { valueAsNumber: true })}
                className='form-control'
              />
            </div>

            {error && (
              <div className='mb-3'>
                <h3>
                  An Error occurred when adding variant please check data and
                  try again!
                </h3>
              </div>
            )}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                modalState?.hide();
              }}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-dark'
              onClick={handleSubmit(handleSubmitModifier)}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
