import { useFormContext } from 'react-hook-form';
import { ProductInput } from '../../../utils/productInputs';
import { Description } from '../../description';
import Categories from './categories';
import Flags from './flags';
import Numbers from './numbers';
import { ErrorMessage } from '@hookform/error-message';
export default function BasicInfo() {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ProductInput>();
  register('description', {
    required: 'Description is required',
  });
  function onDescriptionChange(value: string) {
    setValue('description', value, { shouldDirty: true, shouldValidate: true });
  }
  return (
    <>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Product Name (EN)
            <input
              className='form-control'
              type='text'
              {...register('name', { required: 'Name is required' })}
            />
          </label>
          <ErrorMessage
            errors={errors}
            name='name'
            render={({ message }) => <p className='errorMessage'>{message}</p>}
          />
        </div>
        <div className='col'>
          <label className='col-form-label w-100'>
            Product Name (AR)
            <br />
            <input
              className='form-control'
              type='text'
              {...register('arabicName', {
                required: 'Arabic name is required',
              })}
            />
          </label>
          <ErrorMessage
            errors={errors}
            name='arabicName'
            render={({ message }) => <p className='errorMessage'>{message}</p>}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <label className='col-form-label w-100'>Description</label>
          <Description
            onChange={onDescriptionChange}
            text={getValues('description')}
          />
          <ErrorMessage
            errors={errors}
            name='description'
            render={({ message }) => <p className='errorMessage'>{message}</p>}
          />
        </div>
        <div className='col-12 col-md-4'>
          <Categories />
          <hr />
          <Numbers />
          <hr />
          <Flags />
        </div>
      </div>
    </>
  );
}
