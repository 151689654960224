import { useMemo } from 'react';

interface SelectColumnFilterProps {
  column: any;
}

export default function NumberFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: SelectColumnFilterProps) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);
  return (
    <>
      <input
        type='number'
        className='w-100'
        value={filterValue[0] || ''}
        placeholder={min}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
      />

      <input
        type='number'
        className='w-100'
        value={filterValue[1] || ''}
        placeholder={max}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
      />
    </>
  );
}
