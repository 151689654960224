import axios from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import imageUploadApi from '../../utils/API/imageUploadApi';
import protectedApi, {
  ProductPatchData,
} from '../../utils/API/ProtectectedApi';
import { getDirtyFieldsData } from '../../utils/helperFunctions';
import { ProductEdit } from '../../utils/productInputs';
import { Product } from '../../utils/responses/products';
import BasicInfo from '../productEdit/basicInfo';
import Images from '../productEdit/images';
import Specs from '../productEdit/specs';
import VariantsEdit from '../productEdit/variants/variantsEdit';
import PriceModifierEdit from '../productEdit/priceModifierEdit';
import { PriceModifiers } from '../../utils/responses/priceModifiers';
import PriceModifiersEdit from '../productEdit/pricemodifiers';

interface EdiProductProps {
  product: Product;
  modifiers: PriceModifiers[];
}

export default function EditProductForm({
  product,
  modifiers,
}: EdiProductProps) {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string>();
  const methods = useForm<ProductEdit>({
    defaultValues: {
      name: product.name,
      arabicName: product.arabicName,
      categoryId: product.categoryId,
      itemTypeId: product.itemTypeId,
      MOQ: product.MOQ,
      baseQuantity: product.baseQuantity,
      itemsPerBox: product.itemsPerBox,
      isHidden: product.isHidden,
      description: product.description,
      images: product.images,
      specs: product.specs,
      coverImage: product.coverImage,
      imagesUpload: undefined,
    },
  });

  const { isDirty, dirtyFields } = methods.formState;
  function getCoverImageLink(
    coverImageName: string,
    currentImages: string[],
    imagesUpload?: { fileName: string; key: string }[]
  ) {
    if (imagesUpload) {
      for (const upload of imagesUpload) {
        if (upload.fileName === coverImageName) return upload.key;
      }
    }

    for (const imageKey of currentImages) {
      if (imageKey === coverImageName) return imageKey;
    }
  }

  async function uploadImages(fileList: FileList) {
    const uploadData = await protectedApi.getImageEditLinks(product.slug);
    return await imageUploadApi.uploadMultipleImages(fileList, uploadData.data);
  }

  async function handleSubmit(data: ProductEdit) {
    const dirty = getDirtyFieldsData<ProductEdit>(dirtyFields, isDirty, data);
    if (!dirty) return;
    let newUploads;
    let patchData: ProductPatchData = dirty;
    try {
      if (dirty.imagesUpload) {
        newUploads = await uploadImages(dirty.imagesUpload);
        patchData.images = [
          ...product.images,
          ...newUploads.map((el) => el.key),
        ];
      }
      if (dirty.coverImage) {
        patchData.coverImage = getCoverImageLink(
          dirty.coverImage,
          data.images,
          newUploads
        );
      }
      delete dirty.imagesUpload;
      await protectedApi.patchProduct(product.slug, patchData);
      navigate(-1);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.config.baseURL === process.env.REACT_APP_API)
          setApiError(e.response?.data.errors[0].message);
        else setApiError('Failed to upload images');
      }
    }
  }
  return (
    <div className='windowContainer'>
      <div className='window'>
        <h3 className='border-3 border-bottom border-dark pb-1'>
          Edit Product {product._id}
        </h3>
        <h5 className='m-0'>Vendor: {product.vendor.name}</h5>
        <hr className='mt-2' />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <div className='d-flex justify-content-between'>
              <h5 className='m-0'>
                <em>Product Display Details</em>
              </h5>
              <button
                className='btn btn-warning'
                type='button'
                onClick={(e) =>
                  methods.reset(undefined, {
                    keepDefaultValues: true,
                  })
                }
              >
                Reset
              </button>
            </div>
            <BasicInfo />
            <hr />
            <div className='row'>
              <Images />
              <Specs />
            </div>
            <hr />
            <h5 className='m-0 mb-1'>
              <em>Variant Details</em>
            </h5>
            <VariantsEdit product={product} />
            <hr />
            <h5 className='m-0 mb-1'>
              <em>Price Modifiers Details</em>
            </h5>
            <PriceModifiersEdit
              priceModifiers={modifiers}
              productId={product._id}
            />
            <div className='row'>
              <div className='col text-end d-flex justify-content-between'>
                <h3 className='errorMessage'>{apiError}</h3>
                <button className='btn btn-success' type='submit'>
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
