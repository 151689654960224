import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import BooleanEditUser from '../components/editComponenets/booleanEditUser';
import BooleanFilter from '../components/filters/booleanFilter';
import MainTable from '../components/table';
import protectedApi from '../utils/API/ProtectectedApi';
import { useAuth } from '../utils/authContext';
import { BooleanDisplay } from '../utils/display';
import { UserVendor } from '../utils/responses/vendor';

export default function Vendors() {
  const [vendors, setVendors] = useState<UserVendor[]>([]);
  useEffect(() => {
    async function getVendors() {
      const res = await protectedApi.getVendors();
      setVendors(res.data.data);
    }
    getVendors();
  }, []);
  const [skipPageReset, setSkipPageReset] = useState(false);
  useEffect(() => {
    setSkipPageReset(false);
  }, [vendors]);
  const { userType } = useAuth();
  const columns: any = useMemo(() => {
    return [
      { Header: 'ID', accessor: '_id' },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell:
          userType === 'Admin'
            ? ({ cell }: { cell: Cell<UserVendor> }) => {
                return (
                  <BooleanEditUser
                    cell={cell}
                    field='isActive'
                    updateData={updateData}
                  />
                );
              }
            : BooleanDisplay,
        Filter: BooleanFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: 'Company Name',
        accessor: 'vendor.name',
      },
      {
        Header: 'First Name',
        accessor: 'vendor.contactPersonFirstName',
      },
      {
        Header: 'Last Name',
        accessor: 'vendor.contactPersonLastName',
      },
      {
        Header: 'Organization Email',
        accessor: 'vendor.email',
      },
      {
        Header: 'Organization Phone',
        accessor: 'vendor.phone',
      },
      {
        Header: 'Products',
        Cell: ({ cell: { value } }: { cell: { value: any } }) => {
          return <Link to={`/vendors/${value}`}>Products</Link>;
        },
        disableFilters: true,
        disableSortBy: true,
        accessor: 'vendor._id',
      },
      {
        Header: 'Add Product',
        Cell: (props: any) => {
          return (
            <Link
              to={`/vendors/${props.cell.row.original.vendor._id}/addProduct`}
              state={{ vendorName: props.cell.row.original.vendor.name }}>
              Add Product
            </Link>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ];
  }, []);
  function updateData(rowIndex: number, columnId: string, value: any) {
    setSkipPageReset(true);
    setVendors((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }

  if (vendors.length === 0)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}></span>
        </div>
      </div>
    );
  return (
    <MainTable
      data={vendors}
      columns={columns}
      title='Vendors'
      skipPageRef={skipPageReset}
    />
  );
}
