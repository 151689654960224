import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router';
import EditProductForm from '../components/editComponenets/editProductForm';
import openApi from '../utils/API/openApi';
import protectedApi from '../utils/API/ProtectectedApi';
import { Category } from '../utils/responses/categories';
import { Product } from '../utils/responses/products';
import { PriceModifiers } from '../utils/responses/priceModifiers';

export default function EditProduct() {
  const { slug } = useParams();
  const [product, setProduct] = useState<Product>();
  const [modifiers, setModifiers] = useState<PriceModifiers[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    async function getProduct() {
      try {
        if (!slug) return setIsLoading(false);
        const res = await protectedApi.getOneProduct(slug);
        const modRes = await protectedApi.getPriceModifiers(res.data._id);
        setProduct(res.data);
        setModifiers(modRes.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
    getProduct();
  }, []);

  if (isLoading)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}
          ></span>
        </div>
      </div>
    );
  else if (product && modifiers)
    return (
      <div className='mainContent'>
        <div className='tableContainer'>
          <div className='d-flex justify-content-between align-items-center tableTitle'>
            <h5 className='m-2 fw-bold'>
              <i className='fas fa-box'></i>&nbsp;Products
            </h5>
          </div>
        </div>
        <EditProductForm product={product} modifiers={modifiers} />
      </div>
    );
  else
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>
            <i className='fas fa-exclamation'></i> Error
          </h1>
        </div>
      </div>
    );
}
