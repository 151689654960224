import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { ProductInput } from '../../../utils/productInputs';

export default function Flags() {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductInput>();

  return (
    <div className='row'>
      <div className='col d-flex flex-column justify-content-center align-items-start'>
        <div className='form-check fs-5'>
          <input
            className='form-check-input'
            type='checkbox'
            id='hidden'
            {...register('isHidden')}
          />
          <label className='form-check-label' htmlFor='hidden'>
            Is Hidden?
          </label>
        </div>
      </div>
    </div>
  );
}
