import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import protectedApi from '../../../utils/API/ProtectectedApi';
import { getDirtyFieldsData } from '../../../utils/helperFunctions';
import { Product, ProductVariant } from '../../../utils/responses/products';

interface VariantEditProps {
  product: Product;
  variant: ProductVariant;
  deleteVariant: () => void;
}

export default function VariantEdit(props: VariantEditProps) {
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, isDirty },
  } = useForm<ProductVariant>({
    defaultValues: props.variant,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  async function SubmitVariant(data: ProductVariant) {
    const patchData = getDirtyFieldsData<ProductVariant>(
      dirtyFields,
      isDirty,
      data
    );
    if (!patchData) return;
    try {
      setIsLoading(true);
      setError(undefined);
      await protectedApi.patchVariant(
        props.product._id,
        props.variant._id,
        patchData
      );
      setIsLoading(false);
    } catch (err) {
      if (axios.isAxiosError(err))
        setError(err.response?.data.errors[0].message);
      setIsLoading(false);
    }
  }
  async function deleteVariant() {
    try {
      setDeleteLoading(true);
      await protectedApi.deleteVariant(props.product._id, props.variant._id);
      setDeleteLoading(false);
      props.deleteVariant();
    } catch (err) {
      setDeleteLoading(false);
      setDeleteError(true);
      if (axios.isAxiosError(err)) {
        alert(err.response?.data.message);
      }
    }
  }
  return (
    <React.Fragment>
      <tr>
        <td>{props.variant._id}</td>
        <td>
          <input
            className='form-control'
            {...register('name', { required: 'Name is required' })}
            type='text'
          />
        </td>
        <td>
          <input
            className='form-control'
            {...register('arabicName', { required: 'Arabic Name is required' })}
            type='text'
          />
        </td>
        <td>
          <input
            className='form-control'
            {...register('price', { valueAsNumber: true })}
            type='text'
          />
        </td>
        <td>
          <input
            className='form-control'
            {...register('stagedPrice', { valueAsNumber: true })}
            type='text'
          />
        </td>
        <td>
          <input {...register('isHidden')} type='checkbox' />
        </td>
        <td>
          <input
            className='form-control'
            {...register('stock', { valueAsNumber: true })}
            type='text'
          />
        </td>
        <td>
          <button
            className='btn btn-dark'
            type='button'
            onClick={handleSubmit(SubmitVariant)}>
            {isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'></span>{' '}
                {'Loading...'}
              </>
            ) : (
              'Save'
            )}
          </button>
        </td>
        <td>
          <button
            className='btn btn-danger'
            type='button'
            onClick={deleteVariant}>
            {deleteLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'></span>{' '}
                {'Loading...'}
              </>
            ) : deleteError ? (
              'Error'
            ) : (
              'Delete'
            )}
          </button>
        </td>
      </tr>
      {error && (
        <tr>
          <td colSpan={7}>{error}</td>
        </tr>
      )}
    </React.Fragment>
  );
}
