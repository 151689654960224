interface SelectColumnFilterProps {
  column: any;
}

export default function DateFilter({
  column: { setFilter, filterValue = [] },
}: SelectColumnFilterProps) {
  return (
    <>
      <input
        type='date'
        className='w-100'
        value={
          filterValue[0] instanceof Date
            ? filterValue[0].toISOString().split('T')[0]
            : filterValue[0]
        }
        onChange={(e) => {
          setFilter((old = []) => [new Date(e.target.value), old[1]]);
        }}
      />
      <input
        type='date'
        className='w-100'
        value={
          filterValue[1] instanceof Date
            ? filterValue[1].toISOString().split('T')[0]
            : filterValue[1]
        }
        onChange={(e) => {
          setFilter((old = []) => [old[0], new Date(e.target.value)]);
        }}
      />
    </>
  );
}
