import Truncate from 'react-truncate';
import { Product } from '../../utils/responses/products';

interface ProductAddedCardProps {
  onClick: (product: Product) => void;
  product: Product;
}

export default function ProductAddedCard(props: ProductAddedCardProps) {
  return (
    <div className='collectionProduct'>
      <img
        className='collectionProductImage'
        src={`https://${process.env.REACT_APP_CLOUDFRONT}/${props.product.coverImage}`}
      />
      <h6 className='m-1'>
        <Truncate lines={1}>{props.product.name}</Truncate>{' '}
      </h6>
      <hr className='my-2' />
      <p className='m-1'>
        <Truncate lines={1}>{props.product.vendor.name}</Truncate>{' '}
      </p>
      <p className='text-center m-1'>
        <strong>{props.product.variants[0].price}</strong>
      </p>
      <div
        className='d-flex justify-content-center align-items-center removeProdBtn'
        onClick={() => {
          props.onClick(props.product);
        }}>
        <i className='fa fa-close fs-5'></i>
      </div>
    </div>
  );
}
