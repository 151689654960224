import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import openApi from '../../../utils/API/openApi';
import { ProductInput } from '../../../utils/productInputs';
import { Category } from '../../../utils/responses/categories';
import { ItemType } from '../../../utils/responses/products';

export default function Categories() {
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<ProductInput>();
  const currentCategory = watch('categoryId');
  const [categories, setCategories] = useState<Category[]>([]);
  const [itemTypes, setItemTypes] = useState<ItemType[]>();
  const [isLoading, setIsLoading] = useState(true);
  const initialRender = useRef<boolean>(true);
  async function getItemTypes(categoryId: string) {
    const res = await openApi.getOneCategory(categoryId);
    return res.data.itemTypes;
  }

  useEffect(() => {
    async function getTopCategories() {
      const res = await openApi.getParentCategories();
      setCategories(res.data.data);
      if (!currentCategory)
        setItemTypes(await getItemTypes(res.data.data[0].children[0]._id));
      else setItemTypes(await getItemTypes(currentCategory));
      setIsLoading(false);
      initialRender.current = false;
    }
    getTopCategories();
  }, []);

  useEffect(() => {
    async function getCategoryItemTypes() {
      const itemTypes = await getItemTypes(currentCategory);
      setItemTypes(itemTypes);
      setValue('itemTypeId', itemTypes[0]._id, { shouldDirty: true });
    }
    if (!initialRender.current) getCategoryItemTypes();
  }, [currentCategory]);
  if (isLoading) return <h2>Loading</h2>;
  return (
    <>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Category
            <select
              className='form-select'
              {...register('categoryId', { required: 'Category is required' })}>
              {categories.map((el) => (
                <optgroup label={el.name} key={el._id}>
                  {el.children?.map((el) => (
                    <option value={el._id} key={el._id}>
                      {el.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            <ErrorMessage
              errors={errors}
              name='categoryId'
              render={({ message }) => (
                <p className='errorMessage'>{message}</p>
              )}
            />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Item Type
            <select
              className='form-select'
              {...register('itemTypeId', { required: 'ItemType is required' })}>
              {itemTypes?.map((el) => (
                <option value={el._id} key={el._id}>
                  {el.name}
                </option>
              ))}
            </select>
            <ErrorMessage
              errors={errors}
              name='itemTypeId'
              render={({ message }) => (
                <p className='errorMessage'>{message}</p>
              )}
            />
          </label>
        </div>
      </div>
    </>
  );
}
