import React from 'react';
import { UseFiltersColumnProps } from 'react-table';
import { UserBuyer } from '../../utils/responses/buyer';
import {
  Order,
  PurchaseOrders,
  SingleOrder,
} from '../../utils/responses/orders';
import { Product } from '../../utils/responses/products';
import { UserVendor } from '../../utils/responses/vendor';

interface SelectColumnFilterProps {
  column: UseFiltersColumnProps<
    Product | UserVendor | UserBuyer | Order | SingleOrder | PurchaseOrders
  >;
}

export default function DefaultColumnFilter({
  column,
}: SelectColumnFilterProps) {
  return (
    <input
      type='text'
      className='w-100'
      placeholder='Search...'
      value={column.filterValue}
      onChange={(e) => {
        column.setFilter(e.target.value || undefined);
      }}
    />
  );
}
