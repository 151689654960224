import { UseFiltersColumnProps } from 'react-table';
import { UserBuyer } from '../../utils/responses/buyer';
import { Product } from '../../utils/responses/products';
import { UserVendor } from '../../utils/responses/vendor';

interface SelectColumnFilterProps {
  column: UseFiltersColumnProps<Product | UserBuyer | UserVendor>;
}

export default function BooleanFilter({ column }: SelectColumnFilterProps) {
  return (
    <select
      className='w-100'
      onChange={(e) => {
        column.setFilter(
          e.target.value === 'undefined' ? undefined : e.target.value
        );
      }}>
      <option value={'undefined'}>All</option>
      <option value={1}>Yes</option>
      <option value={0}>No</option>
    </select>
  );
}
