import axios, { AxiosInstance } from 'axios';
import {
  CategoriesResponse,
  CategoryWithItemTypes,
} from '../responses/categories';
import { ItemType } from '../responses/products';

class OpenApi {
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      withCredentials: true,
    });
  }
  public async getParentCategories() {
    const res = await this.instance.get<CategoriesResponse>(
      'categories/parents'
    );
    return res;
  }
  public async getCategories() {
    const res = await this.instance.get<CategoriesResponse>('/categories');
    return res;
  }
  public async getItemTypes() {
    const res = await this.instance.get<ItemType[]>('/itemTypes');
    return res;
  }
  public async getOneCategory(categoryId: string) {
    const res = await this.instance.get<CategoryWithItemTypes>(
      `/categories/${categoryId}`
    );
    return res;
  }
}
const openApi = new OpenApi();
export default openApi;
