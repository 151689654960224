import axios from 'axios';
import { newCollection } from '../responses/collection';
import { ProductImages } from '../responses/products';

type ImageData = Pick<newCollection, 'image'>;
class ImageUploadApi {
  private createFromData(file: File, image: ImageData) {
    const imageFormData = new FormData();
    Object.entries(image.image.fields).forEach(([k, v]) => {
      imageFormData.append(k, v);
    });
    imageFormData.append('Content-Type', file.type);
    imageFormData.append('file', file);
    return imageFormData;
  }

  public async uploadMultipleImages(
    fileList: FileList,
    uploadData: ProductImages
  ) {
    const arr = [...fileList];
    return await Promise.all(
      arr.map(async (el, idx) => {
        const key = await this.uploadImageToS3(el, {
          image: uploadData.images[idx],
        });
        return { key, fileName: el.name };
      })
    );
  }

  public async uploadImageToS3(file: File, uploadData: ImageData) {
    const formData = this.createFromData(file, { image: uploadData.image });
    axios.post(uploadData.image.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
      },
    });
    return uploadData.image.key;
  }
}

const imageUploadApi = new ImageUploadApi();
export default imageUploadApi;
