import { useState } from 'react';
import { Cell } from 'react-table';
import protectedApi from '../../utils/API/ProtectectedApi';
import { useAuth } from '../../utils/authContext';
import { Order } from '../../utils/responses/orders';

export default function MarkPayment({ cell }: { cell: Cell<Order> }) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { userType } = useAuth();
  async function pay() {
    try {
      setIsLoading(true);
      await protectedApi.payOrder(cell.row.original._id);
      setIsLoading(false);
      setSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setError(true);
    }
  }

  return (
    <button
      className={`btn btn-sm btn-${
        error ? 'danger' : success ? 'success' : 'dark'
      }`}
      disabled={
        userType == 'DataEntry' ||
        isLoading ||
        !(cell.row.original.status === 'WAITING_FOR_APPROVAL')
      }
      onClick={pay}>
      {isLoading ? (
        <>
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'></span>{' '}
        </>
      ) : (
        <>{error ? 'Error' : success ? 'Success!' : 'Pay'}</>
      )}
    </button>
  );
}
