import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Cell } from 'react-table';
import BooleanEditUser from '../components/editComponenets/booleanEditUser';
import BooleanFilter from '../components/filters/booleanFilter';
import MainTable from '../components/table';
import protectedApi from '../utils/API/ProtectectedApi';
import { useAuth } from '../utils/authContext';
import { BooleanDisplay } from '../utils/display';
import { UserBuyer } from '../utils/responses/buyer';

export default function Buyers() {
  const [buyers, setBuyers] = useState<UserBuyer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [skipPageReset, setSkipPageReset] = useState(false);
  useEffect(() => {
    setSkipPageReset(false);
  }, [buyers]);
  useEffect(() => {
    async function getBuyers() {
      const res = await protectedApi.getBuyers();
      setBuyers(res.data.data);
      setIsLoading(false);
    }
    getBuyers();
  }, []);
  const { userType } = useAuth();
  const columns: any = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'buyer._id' },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell:
          userType === 'Admin'
            ? ({ cell }: { cell: Cell<UserBuyer> }) => {
                return (
                  <BooleanEditUser
                    cell={cell}
                    field='isActive'
                    updateData={updateData}
                  />
                );
              }
            : BooleanDisplay,
        Filter: BooleanFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: 'Company Name',
        accessor: 'buyer.name',
      },
      {
        Header: 'First Name',
        accessor: 'buyer.contactPersonFirstName',
      },
      {
        Header: 'Last Name',
        accessor: 'buyer.contactPersonLastName',
      },
      {
        Header: 'Organization Email',
        accessor: 'buyer.email',
      },
      {
        Header: 'Organization Phone',
        accessor: 'buyer.phone',
      },
      {
        Header: 'Beds',
        accessor: 'buyer.noOfBeds',
      },
    ];
  }, []);
  function updateData(rowIndex: number, columnId: string, value: any) {
    setSkipPageReset(true);
    setBuyers((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }

  if (isLoading)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}
          ></span>
        </div>
      </div>
    );

  if (buyers.length === 0)
    return (
      <div className='row'>
        <div className='col'>
          <div className='center-notFound'>
            <h1>
              <i className='fas fa-glasses'></i>&nbsp;No Buyers Found
            </h1>
            <button
              className='btn btn-secondary w-50'
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    );
  return (
    <MainTable
      data={buyers}
      columns={columns}
      title='Buyers'
      skipPageRef={skipPageReset}
    />
  );
}
