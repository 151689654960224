import { SetStateAction } from 'react';
import { Product } from '../../utils/responses/products';
import ProductAddedCard from '../collections/prodAddedCard';
interface CurrentProductsProps {
  setCurrentProducts: React.Dispatch<SetStateAction<Product[] | undefined>>;
  currentProducts: Product[] | undefined;
}

export default function CurrentProducts({
  currentProducts,
  setCurrentProducts,
}: CurrentProductsProps) {
  function removeProduct(product: Product) {
    setCurrentProducts((prev) => {
      return prev?.filter((el) => el._id !== product._id);
    });
  }
  return (
    <div className='col-auto border-secondary border-start'>
      <div className='d-flex justify-content-between'>
        <h6>Current Products</h6>
        <button
          className='btn btn-danger mb-2'
          onClick={() => {
            setCurrentProducts([]);
          }}>
          Clear All
        </button>
      </div>
      <div className='d-flex flex-wrap collectionProductContainer'>
        {currentProducts?.map((el) => (
          <ProductAddedCard product={el} onClick={removeProduct} />
        ))}
      </div>
    </div>
  );
}
