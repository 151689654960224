import { sellingPriceTotals } from '../utils/helperFunctions';
import { PurchaseOrders } from '../utils/responses/orders';
import StaticItemsTable from './staticItemsTable';

interface PurchaseOrdersTableProps {
  data: PurchaseOrders;
}

export default function PurchaseOrdersDetails({
  data,
}: PurchaseOrdersTableProps) {
  const { subTotal, taxes, total } = sellingPriceTotals(data);
  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <h2>ID {data._id}</h2>
        </div>
        <div className='col'>
          <h2>Status {data.status}</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h3>Vendor Name {data.vendor.name}</h3>
        </div>
        <div className='col'>
          <h3>Vendor ID {data.vendor._id}</h3>
        </div>
      </div>
      <div className='row'>
        <table className='table'>
          <thead>
            <th>Product Name</th>
            <th>Variant Name</th>
            <th>Quantity</th>
            <th>Selling Price (Buyer)</th>
            <th>Purchase Price (Vendor)</th>
          </thead>
          <tbody>
            {data.items.map((el) => {
              return (
                <tr>
                  <td>{el.productVariant.product.name}</td>
                  <td>{el.productVariant.name}</td>
                  <td>{el.quantity}</td>
                  <td>{el.sellingPrice}</td>
                  <td>{!el.purchasePrice ? 0 : el.purchasePrice}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td>Sub-Total</td>
              <td>{subTotal}</td>
              <td>{data.subTotal}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Taxes</td>
              <td>{taxes}</td>
              <td>{data.taxes}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{total}</td>
              <td>{data.totalPrice}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
