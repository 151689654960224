import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/authContext';
export default function ProductsHomePage() {
  const navigate = useNavigate();
  const { userType } = useAuth();
  return (
    <div className='mainContent'>
      <div className='tableContainer'>
        <div className='d-flex justify-content-between align-items-center tableTitle'>
          <h5 className='m-2 fw-bold'>
            <i className='fas fa-sitemap'></i>&nbsp;Dashboard
          </h5>
        </div>
      </div>
      <div className='dashboardItems'>
        <div
          className='dashboardItem'
          onClick={() => {
            navigate('/products/table');
          }}>
          <i className='fas fa-box'></i>
          <h1 className='text-end'>Products</h1>
          <h5 className='text-end'>1000 Products</h5>
          <h5 className='text-end'>999 Variants</h5>
        </div>

        {userType === 'Admin' && (
          <div
            className='dashboardItem'
            onClick={() => {
              navigate('/products/logs');
            }}>
            <i className='fas fa-clipboard-list'></i>{' '}
            <h1 className='text-end'>Product Logs</h1>
            <h5 className='text-end'>1000 Logs</h5>
            <h5 className='text-end'>999 Users</h5>
          </div>
        )}
      </div>
    </div>
  );
}
