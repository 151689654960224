import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import NumberFilter from '../components/filters/numberFilter';
import PurchaseOrdersDetails from '../components/purchaseOrdersDetails';
import MainTable from '../components/table';
import protectedApi from '../utils/API/ProtectectedApi';
import { Items, SingleOrder } from '../utils/responses/orders';

export default function SingleOrderPage() {
  const { orderId } = useParams();
  const [order, setOrder] = useState<SingleOrder>();
  const [orderItems, setOrderItems] = useState<Items[]>();
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState(true);
  useEffect(() => {
    async function getOrder() {
      if (!orderId) return;
      const res = await protectedApi.getOneOrder(orderId);
      setOrder(res.data);
      setOrderItems(() => {
        return res.data.purchaseOrders.flatMap((el) => el.items);
      });
      setLoading(false);
    }
    getOrder();
  }, []);
  const itemsColumns: any = useMemo(() => {
    return [
      {
        Header: 'Product ID',
        accessor: 'productVariant.product._id',
      },
      {
        Header: 'Product Name',
        accessor: 'productVariant.product.name',
      },
      {
        Header: 'Variant ID',
        accessor: 'productVariant._id',
      },
      {
        Header: 'Variant Name',
        accessor: 'productVariant.name',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Filter: NumberFilter,
        filter: 'between',
      },
      {
        Header: 'Selling Price (excl Taxes)',
        accessor: 'sellingPrice',
        Filter: NumberFilter,
        filter: 'between',
        Footer: (info: any) => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum: any, row: any) => row.values.sellingPrice + sum,
                0
              ),
            [info.rows]
          );
          return <>Total = {total.toFixed(2)}</>;
        },
      },
      {
        Header: 'Purchasing Price (excl Taxes)',
        accessor: 'purchasePrice',
        Filter: NumberFilter,
        filter: 'between',
        Footer: (info: any) => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum: any, row: any) => row.values.purchasePrice + sum,
                0
              ),
            [info.rows]
          );
          return <>Total = {total.toFixed(2)}</>;
        },
      },
    ];
  }, []);
  if (loading) return <h1>L</h1>;
  if (!order || !orderItems) return <h1>ERROR</h1>;
  return (
    <React.Fragment>
      <div className='row'>
        <div className='col text-end'>
          <button
            className='btn btn-dark'
            onClick={() => {
              setView(!view);
            }}>
            Change View
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {view ? (
            order.purchaseOrders.map((el) => {
              return <PurchaseOrdersDetails data={el} key={el._id} />;
            })
          ) : (
            <MainTable
              data={orderItems}
              columns={itemsColumns}
              title={`Order #${order._id}`}
              skipPageRef={false}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
