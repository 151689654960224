import { useEffect, useMemo, useState } from 'react';
import DateFilter from '../components/filters/dateFilter';
import SelectFilter from '../components/filters/selectFilter';
import MainTable from '../components/table';
import protectedApi from '../utils/API/ProtectectedApi';
import { DateDisplay } from '../utils/display';
import { dateFilter } from '../utils/helperFunctions';
import { ProductLog } from '../utils/responses/productLogs';

export default function ProductLogs() {
  const [productLogs, setProductLogs] = useState<ProductLog[]>([]);
  useEffect(() => {
    async function getLogs() {
      const res = await protectedApi.getLogs();
      setProductLogs(res.data);
    }
    getLogs();
  }, []);
  const columns: any = useMemo(() => {
    return [
      {
        Header: 'Product ID',
        accessor: 'product._id',
      },
      {
        Header: 'Product Name',
        accessor: 'product.name',
      },
      { Header: 'Username', accessor: 'user.username' },
      { Header: 'UserType', accessor: 'user.userType', Filter: SelectFilter },
      { Header: 'Operation', accessor: 'operation', Filter: SelectFilter },
      {
        Header: 'TimeStamp',
        accessor: 'timestamp',
        Cell: DateDisplay,
        Filter: DateFilter,
        filter: dateFilter,
      },
    ];
  }, []);

  if (productLogs.length === 0)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}></span>
        </div>
      </div>
    );
  return (
    <MainTable
      data={productLogs}
      columns={columns}
      title='Product Logs'
      skipPageRef={false}
    />
  );
}
