import { SetStateAction, useState } from 'react';
import protectedApi from '../../utils/API/ProtectectedApi';
import { Product } from '../../utils/responses/products';
import ProductResultCard from './productResultCard';

interface SearchProductProps {
  setSearchResults: React.Dispatch<SetStateAction<Product[] | undefined>>;
  setCurrentProducts: React.Dispatch<SetStateAction<Product[] | undefined>>;
  searchResults: Product[] | undefined;
}

export default function SearchProduct({
  searchResults,
  setSearchResults,
  setCurrentProducts,
}: SearchProductProps) {
  const [searchType, setSearchType] = useState<string>('name');
  const [q, setQ] = useState<string>();

  async function getSearchResults() {
    if (!q || q.length < 3) return;
    if (searchType !== 'name' && searchType !== 'id') return;
    const res = await protectedApi.getSearchResults(searchType, q);
    setSearchResults(res?.data);
  }

  function addProduct(product: Product) {
    setCurrentProducts((prev) => {
      if (prev?.find((el) => el._id === product._id)) return prev;
      if (!prev) return [product];
      return [...prev, product];
    });
  }
  return (
    <div className='col'>
      <h6>Add a new product</h6>
      <div className='d-flex d-xl-flex flex-fill justify-content-center align-items-center justify-content-xl-center align-items-xl-center searchBarContainer'>
        <select
          className='form-select me-2 mb-2 selCollection'
          onChange={(e) => {
            setSearchType(e.target.value);
          }}
          value={searchType}>
          <option value='id'>ID</option>
          <option value='name'>Name</option>
        </select>
        <input
          className='form-control d-flex w-100 search-bar'
          type='text'
          value={q}
          onChange={(e) => setQ(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') getSearchResults();
          }}
          placeholder='Search For Products'
        />
        <i className='fa fa-search search-icn' onClick={getSearchResults}></i>
        <div className='suggest-list'>
          <a className='noFormatLink' href='#'>
            <div className='d-flex justify-content-between align-items-center suggest-item d-none'>
              <h5 className='suggestText'>Suggestion</h5>
              <i className='fa fa-arrow-right suggestIcon'></i>
            </div>
          </a>
        </div>
      </div>
      <div className='searchProductResult'>
        {!searchResults || searchResults.length === 0 ? (
          <h5
            style={{
              fontSize: '2rem',
              margin: 'auto',
            }}>
            <i className='fas fa-glasses'></i>&nbsp;No Products Found
          </h5>
        ) : (
          searchResults?.map((el) => (
            <ProductResultCard product={el} key={el._id} onClick={addProduct} />
          ))
        )}
      </div>
    </div>
  );
}
