import { Modal } from 'bootstrap';
import { useState, useRef, useEffect } from 'react';
import protectedApi from '../../../utils/API/ProtectectedApi';
import { Product, ProductVariant } from '../../../utils/responses/products';
import ProductVariantModal from '../../editComponenets/productVariantModal';
import VariantEdit from './variantEdit';

interface VariantsEditProps {
  product: Product;
}

export default function VariantsEdit({ product }: VariantsEditProps) {
  const [variants, setVariants] = useState<ProductVariant[]>(product.variants);
  const modalRef = useRef<HTMLDivElement>(null);

  async function setVariantsArray(data: ProductVariant) {
    const prodctRes = await protectedApi.getOneProduct(product.slug);
    setVariants(prodctRes.data.variants);
  }
  const [modalState, setModalState] = useState<Modal>();
  useEffect(() => {
    if (!modalRef.current) return;
    setModalState(new Modal(modalRef.current));
  }, [modalRef.current]);

  async function deleteVariant() {
    const prodctRes = await protectedApi.getOneProduct(product.slug);
    setVariants(prodctRes.data.variants);
  }

  return (
    <>
      <ProductVariantModal
        modalRef={modalRef}
        productId={product._id}
        setVariants={setVariantsArray}
        modalState={modalState}
      />
      <div className='row'>
        <div className='col'>
          <div className='table-responsive text-start'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Variant ID</th>
                  <th>Name [EN]</th>
                  <th>Name [AR]</th>
                  <th>Price</th>
                  <th>Price [Staged]</th>
                  <th>Hidden</th>
                  <th>Stock</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {variants.map((el) => (
                  <VariantEdit
                    key={el._id}
                    product={product}
                    variant={el}
                    deleteVariant={deleteVariant}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      className='btn btn-dark'
                      type='button'
                      onClick={() => {
                        modalState?.show();
                      }}>
                      Add
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
