import { ErrorMessage } from '@hookform/error-message';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ProductInput, VariantInput } from '../../../utils/productInputs';

export default function VariantsAdd() {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductInput>();
  const { fields, append, remove } = useFieldArray<ProductInput, 'variants'>({
    name: 'variants',
  });
  return (
    <div className='row'>
      <div className='col'>
        <div className='table-responsive text-start'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name [EN]</th>
                <th>Name [AR]</th>
                <th>Price</th>
                <th>Price [Staged]</th>
                <th>Hidden</th>
                <th>Stock</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, idx) => {
                return (
                  <tr key={field.id}>
                    <td>
                      <input
                        className='form-control'
                        type='text'
                        {...register(`variants.${idx}.name`, {
                          required: 'Name is required',
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`variants.${idx}.name`}
                        render={({ message }) => (
                          <p className='errorMessage'>{message}</p>
                        )}
                      />
                    </td>
                    <td>
                      <input
                        className='form-control'
                        type='text'
                        {...register(`variants.${idx}.arabicName`, {
                          required: 'Arabic name is required',
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`variants.${idx}.arabicName`}
                        render={({ message }) => (
                          <p className='errorMessage'>{message}</p>
                        )}
                      />
                    </td>
                    <td>
                      <input
                        className='form-control'
                        type='number'
                        step={0.01}
                        {...register(`variants.${idx}.price`, {
                          valueAsNumber: true,
                          required: 'Price is required',

                          min: {
                            value: 1,
                            message: 'Min price is 1',
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`variants.${idx}.price`}
                        render={({ message }) => (
                          <p className='errorMessage'>{message}</p>
                        )}
                      />
                    </td>
                    <td>
                      <input
                        className='form-control'
                        type='number'
                        step={0.01}
                        {...register(`variants.${idx}.stagedPrice`, {
                          valueAsNumber: true,
                          required: 'Staged price is required',
                          min: {
                            value: 1,
                            message: 'Min staged price is 1',
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`variants.${idx}.stagedPrice`}
                        render={({ message }) => (
                          <p className='errorMessage'>{message}</p>
                        )}
                      />
                    </td>
                    <td>
                      <input
                        type='checkbox'
                        {...register(`variants.${idx}.isHidden`)}
                      />
                    </td>
                    <td>
                      <input
                        className='form-control'
                        type='number'
                        {...register(`variants.${idx}.stock`, {
                          valueAsNumber: true,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`variants.${idx}.stock`}
                        render={({ message }) => (
                          <p className='errorMessage'>{message}</p>
                        )}
                      />
                    </td>
                    <td className='text-end'>
                      <button
                        className='btn btn-danger btn-sm me-2'
                        type='button'
                        disabled={fields.length === 1 ? true : false}
                        onClick={() => {
                          remove(idx);
                        }}>
                        <i className='fas fa-trash'></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <button
                    className='btn btn-success btn-sm'
                    type='button'
                    onClick={() => {
                      append({
                        name: '',
                        arabicName: '',
                        stagedPrice: 0,
                        price: 0,
                        stock: 0,
                        isHidden: false,
                      });
                    }}>
                    Add Variant
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
