import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/fontawesome5-overrides.min.css';
import './assets/fonts/fontawesome-all.min.css';
import './assets/fonts/font-awesome.min.css';
import './assets/css/styles.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'bootstrap';

import HomePage from './pages/homePage';
import { Route, Routes } from 'react-router';
import Products from './pages/products';
import Vendors from './pages/vendors';
import Buyers from './pages/buyers';
import Orders from './pages/orders';
import SingleOrderPage from './pages/singleOrder';
import Login from './pages/login';
import { useAuth } from './utils/authContext';
import Navigation from './components/navigation';
import EditProduct from './pages/editProdct';
import Collections from './pages/collections';
import AddCollection from './pages/addCollection';
import EditCollection from './pages/editCollection';
import ProductsHomePage from './pages/productHomePage';
import ProductLogs from './pages/productLogs';
import AddProduct from './pages/addProduct';

function App() {
  const { AuthState, userType } = useAuth();
  if (!AuthState)
    return (
      <Routes>
        <Route path='*' element={<Login />} />
      </Routes>
    );
  else
    return (
      <>
        <Navigation />
        <Routes>
          <Route path='/'>
            <Route index element={<HomePage />} />

            <Route path='/products'>
              <Route index element={<ProductsHomePage />} />
              <Route path='logs' element={<ProductLogs />} />
              <Route path='table' element={<Products />} />
              <Route path=':slug' element={<EditProduct />} />
            </Route>
            <Route path='/vendors'>
              <Route index element={<Vendors />} />
              <Route path=':vendorId'>
                <Route index element={<Products />} />
                <Route path='addProduct' element={<AddProduct />} />
              </Route>
            </Route>
            <Route path='/buyers' element={<Buyers />} />
            <Route path='/orders'>
              <Route index element={<Orders />} />
              <Route path=':orderId' element={<SingleOrderPage />} />
            </Route>
            <Route path='/collections'>
              <Route index element={<Collections />} />
              <Route path='new' element={<AddCollection />} />
              <Route path=':slug' element={<EditCollection />} />
            </Route>
          </Route>
        </Routes>
      </>
    );
}

export default App;
