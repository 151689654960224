import { ErrorMessage } from '@hookform/error-message';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ProductInput } from '../../utils/productInputs';

export default function Specs() {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductInput>();
  const { fields, append, remove } = useFieldArray<ProductInput, 'specs'>({
    name: 'specs',
  });
  const PREDFINED_SPECS = ['Size', 'Country of Origin', 'Brand'];

  return (
    <div className='col-4'>
      <label className='form-label w-100'>
        Specs
        <br />
      </label>
      <div className='table-responsive'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, idx) => {
              return (
                <tr key={field.id}>
                  <td className='p-0 align-top'>
                    <input
                      className='form-control'
                      type='text'
                      disabled={PREDFINED_SPECS.includes(field.name)}
                      {...register(`specs.${idx}.name` as const, {
                        required: 'Spec value is required',
                        validate: {
                          default: (spec) =>
                            spec !== 'spec' || 'Spec name must be changed',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`specs.${idx}.name`}
                      render={({ message }) => (
                        <p className='errorMessage'>{message}</p>
                      )}
                    />
                  </td>
                  <td className='p-0 align-top'>
                    <input
                      className='form-control'
                      type='text'
                      {...register(`specs.${idx}.value` as const, {
                        required: 'Value is required',
                        validate: {
                          default: (value) =>
                            value !== 'value' || 'Value must be changed',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`specs.${idx}.value`}
                      render={({ message }) => (
                        <p className='errorMessage'>{message}</p>
                      )}
                    />
                    {!PREDFINED_SPECS.includes(field.name) && (
                      <p
                        className='fw-light mb-0 delLink'
                        style={{ textAlign: 'right' }}
                        onClick={() => {
                          remove(idx);
                        }}>
                        [Delete Spec]
                      </p>
                    )}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className='p-0 align-top'></td>
              <td className='text-end p-0 align-top'>
                <p className='mb-0 genLink'>
                  <em
                    onClick={() => {
                      append({ name: 'spec', value: 'value' });
                    }}>
                    + Add Spec
                  </em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
