import '@toast-ui/editor/dist/toastui-editor.css';

import { Editor } from '@toast-ui/react-editor';
import React, { createRef, useEffect, useRef } from 'react';

interface DescriptionProps {
  text?: string;
  onChange: (value: string) => void;
}

export const Description: React.FC<DescriptionProps> = (props) => {
  const editorRef = createRef<Editor>();
  function handleChange() {
    if (!editorRef.current?.getInstance()) return;
    props.onChange(editorRef.current?.getInstance().getMarkdown());
  }

  return (
    <Editor
      initialValue={props.text}
      initialEditType='wysiwyg'
      hideModeSwitch={false}
      onChange={handleChange}
      height='600px'
      ref={editorRef}
    />
  );
};
