import { Modal } from 'bootstrap';
import { useState, useRef, useEffect } from 'react';
import protectedApi from '../../utils/API/ProtectectedApi';

import { PriceModifiers } from '../../utils/responses/priceModifiers';
import PriceModifierEdit from './priceModifierEdit';
import PriceModifierModal from '../editComponenets/priceModifierModal';

interface PriceModifiersEditProps {
  priceModifiers: PriceModifiers[];
  productId: string;
}

export default function PriceModifiersEdit({
  priceModifiers,
  productId,
}: PriceModifiersEditProps) {
  const [modifiers, setModifiers] = useState<PriceModifiers[]>(priceModifiers);
  const modalRef = useRef<HTMLDivElement>(null);

  async function setModifiersArray(data: PriceModifiers) {
    const prodctRes = await protectedApi.getPriceModifiers(productId);
    setModifiers(prodctRes.data);
  }

  const [modalState, setModalState] = useState<Modal>();
  useEffect(() => {
    if (!modalRef.current) return;
    setModalState(new Modal(modalRef.current));
  }, [modalRef.current]);

  return (
    <>
      <PriceModifierModal
        modalRef={modalRef}
        productId={productId}
        setPriceModifiers={setModifiersArray}
        modalState={modalState}
      />
      <div className='row'>
        <div className='col'>
          <div className='table-responsive text-start'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Variant ID</th>
                  <th>Buyer ID</th>
                  <th>Modifier</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {modifiers.map((el) => (
                  <PriceModifierEdit {...el} key={el.variantId} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      className='btn btn-dark'
                      type='button'
                      onClick={() => {
                        modalState?.show();
                      }}
                    >
                      Add
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
