import axios from 'axios';
import { useState } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router';
import BasicInfo from '../components/productEdit/basicInfo/index';
import Images from '../components/productEdit/images';
import Specs from '../components/productEdit/specs';
import VariantsAdd from '../components/productEdit/variants/variantsAdd';
import imageUploadApi from '../utils/API/imageUploadApi';
import protectedApi, { ProductPostData } from '../utils/API/ProtectectedApi';
import { ProductInput } from '../utils/productInputs';

export default function AddProduct() {
  const { vendorId } = useParams();
  const { state } = useLocation();
  const [apiError, setApiError] = useState<string>();
  const navigate = useNavigate();
  const methods = useForm<ProductInput>({
    defaultValues: {
      specs: [
        {
          name: 'Size',
        },
        {
          name: 'Country of Origin',
        },
        {
          name: 'Brand',
        },
      ],
      variants: [
        {
          name: '',
          arabicName: '',
          stagedPrice: 0,
          price: 0,
          stock: 0,
          isHidden: false,
        },
      ],
      isHidden: false,
      images: [],
    },
  });

  function getCoverImageLink(
    imagesUpload: { fileName: string; key: string }[],
    coverImageName: string
  ) {
    let coverImage = 'default.jpg';
    imagesUpload.forEach((el) => {
      if (el.fileName === coverImageName) coverImage = el.key;
    });
    return coverImage;
  }

  async function handleSubmit(data: ProductInput) {
    if (!vendorId) return;
    try {
      const images = await protectedApi.requestNewProduct(vendorId);
      const imagesUploaded = await imageUploadApi.uploadMultipleImages(
        data.imagesUpload,
        images.data
      );
      const coverImage = getCoverImageLink(imagesUploaded, data.coverImage);
      let productPostData: ProductPostData = {
        ...data,
        images: imagesUploaded.map((el) => el.key),
        vendorId,
        coverImage,
      };
      delete productPostData.imagesUpload;
      await protectedApi.postOneProduct(productPostData);
      navigate('/vendors');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.config.baseURL === process.env.REACT_APP_API)
          setApiError(e.response?.data.errors[0].message);
        else setApiError('Failed to upload images');
      }
    }
  }
  return (
    <div className='mainContent'>
      <div className='tableContainer'>
        <div className='d-flex justify-content-between align-items-center tableTitle'>
          <h5 className='m-2 fw-bold'>
            <i className='fas fa-box'></i>&nbsp;Products
          </h5>
        </div>
      </div>
      <div className='windowContainer'>
        <div className='window'>
          <h3 className='border-3 border-bottom border-dark pb-1'>
            Add a new Product
          </h3>
          <h5 className='m-0'>Vendor: {state.vendorName}</h5>
          <hr className='mt-2' />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <h5 className='m-0'>
                <em>Product Display Details</em>
              </h5>
              <BasicInfo />
              <hr />
              <div className='row'>
                <Images />
                <Specs />
              </div>
              <hr />
              <h5 className='m-0 mb-1'>
                <em>Variant Details</em>
              </h5>
              <VariantsAdd />
              <hr />
              <div className='row'>
                <div className='col text-end d-flex justify-content-between'>
                  <h3 className='errorMessage'>{apiError}</h3>
                  <button className='btn btn-success' type='submit'>
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
