import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import CollectionBasicInfo from '../components/collections/basicInfo';
import CurrentProducts from '../components/collections/currentProducts';
import SearchProduct from '../components/collections/searchProduct';
import imageUploadApi from '../utils/API/imageUploadApi';
import protectedApi from '../utils/API/ProtectectedApi';
import { CollectionInput } from '../utils/collectionInput';
import { Product } from '../utils/responses/products';

export default function AddCollection() {
  const methods = useForm<CollectionInput>();
  const [searchResults, setSearchResults] = useState<Product[]>();
  const [currentProducts, setCurrentProducts] = useState<Product[]>();
  const [apiError, setApiError] = useState<string>();
  const navigate = useNavigate();
  async function submitCollection(data: CollectionInput) {
    const file = data.coverImage.item(0);
    if (!currentProducts || currentProducts.length <= 0) return;
    try {
      const productSlugs = currentProducts.map((el) => el.slug);
      let postData: {
        _id?: string;
        coverImage?: string;
        products: string[];
        name: string;
        arabicName: string;
        description: string;
      } = {
        arabicName: data.arabicName,
        name: data.name,
        products: productSlugs,
        description: data.description,
      };
      if (file) {
        const newCollection = (await protectedApi.requestNewCollection()).data;
        const coverImage = await imageUploadApi.uploadImageToS3(
          file,
          newCollection
        );
        postData = {
          _id: newCollection._id,
          ...data,
          coverImage,
          products: productSlugs,
        };
      }
      const res = await protectedApi.postCollection(postData);
      navigate('/collections');
    } catch {
      setApiError('Failed to create collection');
    }
  }

  return (
    <div className='mainContent'>
      <div className='tableContainer'>
        <div className='d-flex justify-content-between align-items-center tableTitle'>
          <h5 className='m-2 fw-bold'>
            <i className='fas fa-boxes'></i>&nbsp;Collections
          </h5>
        </div>
      </div>
      <div className='windowContainer'>
        <div className='window'>
          <h3 className='border-3 border-bottom border-dark pb-1'>
            Add a new Collection
          </h3>
          <form onSubmit={methods.handleSubmit(submitCollection)} id='colForm'>
            <h5 className='m-0'>
              <em>Collection Info</em>
            </h5>
            <FormProvider {...methods}>
              <CollectionBasicInfo />
            </FormProvider>
            <hr />
            <h5 className='m-0 mb-1'>
              <em>Collection Products</em>
            </h5>
          </form>
          <div className='row'>
            <SearchProduct
              searchResults={searchResults}
              setCurrentProducts={setCurrentProducts}
              setSearchResults={setSearchResults}
            />
            <CurrentProducts
              currentProducts={currentProducts}
              setCurrentProducts={setCurrentProducts}
            />
          </div>
          <div className='row'>
            <div className='col d-flex justify-content-end mt-2'>
              <button className='btn btn-dark' type='submit' form='colForm'>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
