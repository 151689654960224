import { ProductVariant } from '../utils/responses/products';

interface VariantTableProps {
  variants: ProductVariant[];
}
export default function VariantTable(props: VariantTableProps) {
  const variantColumns = [
    'Name [EN]',
    'Name [AR]',
    'Price',
    'Stage Price',
    'Stock',
  ];
  return (
    <table className='table table-secondary mb-0'>
      <thead>
        {variantColumns.map((el) => {
          return <th key={el}>{el}</th>;
        })}
      </thead>
      <tbody>
        {props.variants.map((el) => {
          return (
            <tr key={el._id}>
              <td>{el.name}</td>
              <td>{el.arabicName}</td>
              <td>{el.price}</td>
              <td>{el.stagedPrice}</td>
              <td>{el.stock}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
