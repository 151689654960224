import { useState } from 'react';
import { Cell } from 'react-table';
import protectedApi from '../../utils/API/ProtectectedApi';
import { UserBuyer } from '../../utils/responses/buyer';
import { UserVendor } from '../../utils/responses/vendor';

export default function BooleanEditUser({
  cell,
  field,
  updateData,
}: {
  cell: Cell<UserVendor> | Cell<UserBuyer>;
  field: string;
  updateData: (rowIndex: number, columnId: string, value: any) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function editUser(state: boolean) {
    setIsLoading(true);
    try {
      const res = await protectedApi.patchOneUser(cell.row.original.username, {
        [field]: state,
      });
      updateData(cell.row.index, cell.column.id, state);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <div className='dropdown'>
      <button
        className='btn btn-light btn-sm dropdown-toggle tableDropDown'
        aria-expanded='false'
        data-bs-toggle='dropdown'
        type='button'>
        {isLoading ? (
          <span
            className='spinner-border spinner-border-sm'
            role='status'></span>
        ) : cell.value ? (
          'Yes'
        ) : (
          'No'
        )}
        &nbsp;
      </button>
      <div className='dropdown-menu tableDropDown'>
        <a
          className='dropdown-item'
          onClick={() => {
            if (!cell.value) editUser(true);
          }}>
          Yes
        </a>
        <a
          className='dropdown-item'
          href='#'
          onClick={() => {
            if (cell.value) editUser(false);
          }}>
          No
        </a>
      </div>
    </div>
  );
}
