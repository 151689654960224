import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import protectedApi from '../utils/API/ProtectectedApi';
import { Collection } from '../utils/responses/collection';

export default function Collections() {
  const navigate = useNavigate();

  const [collections, setCollections] = useState<Collection[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  useEffect(() => {
    async function getCollections() {
      try {
        const res = await protectedApi.getCollections();
        setCollections(res.data.data);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        setError(true);
      }
    }
    getCollections();
  }, [onDelete]);

  async function deleteCollection(slug: string) {
    if (!window.confirm('Are you sure you want to delete the collection?'))
      return;
    try {
      await protectedApi.deleteCollection(slug);
      setOnDelete(!onDelete);
    } catch {
      setDeleteError(true);
    }
  }

  if (isLoading)
    return (
      <div className='mainContent'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100'>
          <h1>Loading</h1>
          <span
            className='spinner-border'
            role='status'
            style={{ width: '7rem', height: '7rem' }}
          ></span>
        </div>
      </div>
    );
  return (
    <div className='mainContent'>
      <div className='tableContainer'>
        <div className='d-flex justify-content-between align-items-center tableTitle'>
          <h5 className='m-2 fw-bold'>
            <i className='fas fa-boxes'></i>&nbsp;Collections
          </h5>
          <button
            className='btn btn-dark'
            onClick={() => navigate('/collections/new')}
          >
            Add Collection
          </button>
        </div>
      </div>
      <div className='collectionList'>
        {collections.map((el) => (
          <div className='collectionItem'>
            <div className='row'>
              <div className='col-6 d-flex justify-content-start align-items-center'>
                <h4 className='m-0'>
                  <i className='fas fa-boxes pe-2 border-end border-3'></i>
                  &nbsp;
                  {el.name}
                </h4>
              </div>
              <div className='col border-start'>
                <div className='d-flex align-items-center my-1'>
                  <h6 className='m-0'>Link:&nbsp;</h6>
                  <input
                    type='text'
                    className='w-100'
                    value={`https://www.ultrateb.com/collections/${el.slug}`}
                    readOnly
                  />
                  <button
                    className='btn btn-light btn-sm mx-1'
                    type='button'
                    onClick={() => {
                      navigate(`/collections/${el.slug}`);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className='btn btn-danger btn-sm'
                    type='button'
                    onClick={() => {
                      deleteCollection(el.slug);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
