import { useFormContext } from 'react-hook-form';
import { CollectionInput } from '../../utils/collectionInput';

export default function CollectionBasicInfo() {
  const { register } = useFormContext<CollectionInput>();
  return (
    <>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Name
            <input
              className='form-control'
              type='text'
              {...register('name', { required: true })}
            />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Arabic Name
            <input
              dir='rtl'
              className='form-control'
              type='text'
              {...register('arabicName', { required: true })}
            />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Description
            <textarea
              className='form-control'
              {...register('description', { required: true })}></textarea>
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label className='col-form-label w-100'>
            Cover Image
            <br />
            <input
              className='form-control'
              type='file'
              accept='image/*'
              {...register('coverImage')}
            />
          </label>
        </div>
      </div>
    </>
  );
}
