import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/authContext';
export default function HomePage() {
  const navigate = useNavigate();
  const { userType } = useAuth();
  return (
    <div className='mainContent'>
      <div className='tableContainer'>
        <div className='d-flex justify-content-between align-items-center tableTitle'>
          <h5 className='m-2 fw-bold'>
            <i className='fas fa-sitemap'></i>&nbsp;Dashboard
          </h5>
        </div>
      </div>
      <div className='dashboardItems'>
        <div
          className='dashboardItem'
          onClick={() => {
            navigate('/products');
          }}>
          <i className='fas fa-box'></i>
          <h1 className='text-end'>Products</h1>
          <h5 className='text-end'>1000 Products</h5>
          <h5 className='text-end'>999 Variants</h5>
        </div>
        <div
          className='dashboardItem'
          onClick={() => {
            navigate('/vendors');
          }}>
          <i className='fas fa-warehouse'></i>
          <h1 className='text-end'>Vendors</h1>
          <h5 className='text-end'>1000 Products</h5>
          <h5 className='text-end'>999 Variants</h5>
        </div>
        <div
          className='dashboardItem'
          onClick={() => {
            navigate('/buyers');
          }}>
          <i className='fas fa-hospital'></i>
          <h1 className='text-end'>Hospitals</h1>
          <h5 className='text-end'>1000 Products</h5>
          <h5 className='text-end'>999 Variants</h5>
        </div>
        <div
          className='dashboardItem'
          onClick={() => {
            navigate('/orders');
          }}>
          <i className='fas fa-shopping-bag'></i>
          <h1 className='text-end'>Orders</h1>
          <h5 className='text-end'>1000 Products</h5>
          <h5 className='text-end'>999 Variants</h5>
        </div>
        <div
          className='dashboardItem'
          onClick={() => {
            navigate('/collections');
          }}>
          <i className='fas fa-shopping-basket'></i>{' '}
          <h1 className='text-end'>Collections</h1>
          <h5 className='text-end'>1000 Collections</h5>
          <h5 className='text-end'>999 Buyers</h5>
        </div>
      </div>
    </div>
  );
}
